import { faCircle, faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useExpanded, useSortBy, useTable } from 'react-table';
import { useAccount } from '../../../hooks';
import { BRAND_WO_STATUS_WITHOUT_COLUMNS } from '../../../shared/Constants/Constants';
import { getCssClassNames } from '../../../shared/Utils/ReactTableUtils';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import { ColumnSort } from '../TableCells/TableCells';
import { WORK_ORDER_ITEMS_DEPTH_ACCESSOR_CLASSNAME_MAP } from '../WokrOrderConstants';

const WorkOrderItems = (props) => {
  const { data, isUserServiceProvider, isWorkOrderOutgoing, status, isHUL } = props;
  const tableColumns = props.columns(props);
  let columns = React.useMemo(() => tableColumns, [data]);
  const account = useAccount();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: columns,
      data: data,
      autoResetExpanded: false,
      initialState: {
        hiddenColumns: [
          !isHUL && 'poLineItem',
          !isWorkOrderOutgoing &&
            BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) &&
            'fulfilledQty',
          !isWorkOrderOutgoing && BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) && 'plannedQty',
          BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) && 'creditsAssigned',
        ],
      },
    },
    useSortBy,
    useExpanded
  );

  // useEffect(() => {
  //   props.setExpandedRows && props.setExpandedRows(expanded);
  // }, [expanded]);

  return (
    <Table {...getTableProps()} responsive className="table-bordered">
      <thead className="thead-light table-heading">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={'d-flex flex-row align-items-center'}>
                  {column.render('Header')}
                  <span className={'ml-auto mr-1'}>
                    {column.canSort && column.id !== 'check' ? (
                      <ColumnSort column={column} />
                    ) : null}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={'work-order-items' + i}>
                <tr {...row.getRowProps()} className={classNames({ 'delivery-table': row.depth })}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={getCssClassNames(
                          cell,
                          WORK_ORDER_ITEMS_DEPTH_ACCESSOR_CLASSNAME_MAP
                        )}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row?.isExpanded ? (
                  <>
                    <tr className={'border-top-2 delivery-table'}>
                      <td />
                      <td />
                      <td />
                      {isUserServiceProvider && isWorkOrderOutgoing ? <td /> : null}
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Delivery Id
                      </td>
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Attribution Month
                      </td>
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Last updated
                      </td>
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Fulfilled Qty
                      </td>
                      {!BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) && (
                        <td rowSpan="2" className={'delivery-table-heading'}></td>
                      )}
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Status
                      </td>
                      <td colSpan={4} className={'text-center document-stat-heading'}>
                        Documents (Pick up and Drop off)
                      </td>
                      <td rowSpan="2" className={'delivery-table-heading'}>
                        Actions
                      </td>
                    </tr>
                    <tr className={'delivery-table'}>
                      <td />
                      <td />
                      <td />
                      {isUserServiceProvider && isWorkOrderOutgoing ? <td /> : null}
                      <td className={'delivery-table-heading cell-stat-total'}>Total docs</td>
                      <td className="cell-stat-review">
                        <FontAwesomeIcon icon={faCircle} size={'xs'} className={'mr-2'} />
                        For Review
                      </td>
                      <td className="cell-stat-reject">
                        <FontAwesomeIcon icon={faCircle} size={'xs'} className={'mr-2'} />
                        Rejected
                      </td>
                      <td className="cell-stat-approved">
                        <FontAwesomeIcon icon={faCircle} size={'xs'} className={'mr-2'} />
                        Approved
                      </td>
                    </tr>
                    {!row.subRows.length ? (
                      <tr>
                        <td colSpan={columns?.length}>
                          <p className={'text-center m-2'}>No deliveries found.</p>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan={columns?.length}>
              <p className={'text-center m-3'}>No targets found.</p>
            </td>
          </tr>
        )}
        <tr>
          <td
            colSpan={isUserServiceProvider && isWorkOrderOutgoing ? 6 : 4}
            className={'text-right font-semibold border-0'}>
            <h6>Total</h6>
          </td>
          <td className={'border-bottom font-bold'}>
            {Number(
              parseFloat(
                rows
                  .map(({ depth, values }) => (depth === 0 ? values?.qty ?? 0 : 0))
                  ?.reduce((prev, next) => prev + next, 0) || 0
              ).toFixed(3)
            )}{' '}
            Kg
          </td>
          {!(isUserServiceProvider && isWorkOrderOutgoing) &&
            !BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) && (
              <td className={'border-bottom font-bold'}>
                {Number(
                  parseFloat(
                    rows
                      .map(({ depth, values }) => (depth === 0 ? values?.plannedQty ?? 0 : 0))
                      ?.reduce((prev, next) => prev + next, 0) || 0
                  ).toFixed(3)
                )}{' '}
                Kg
              </td>
            )}
          {!BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status) && (
            <td className={'border-bottom font-bold'}>
              {Number(
                parseFloat(
                  rows
                    .map(({ depth, values }) => (depth === 0 ? values?.fulfilledQty ?? 0 : 0))
                    ?.reduce((prev, next) => prev + next, 0) || 0
                ).toFixed(3)
              )}{' '}
              Kg
            </td>
          )}
          <td
            colSpan={
              isUserServiceProvider && isWorkOrderOutgoing
                ? 4
                : !BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(status)
                ? 5
                : 4
            }
            style={{ border: 'none' }}></td>

          <td className={'border-bottom font-bold'}>
            <FontAwesomeIcon icon={faRupeeSign} />
            &nbsp;
            {roundOffAmount(
              rows
                .map(({ depth, values }) => (depth === 0 ? values?.lineTotal ?? 0 : 0))
                ?.reduce((prev, next) => prev + next, 0)
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default WorkOrderItems;
