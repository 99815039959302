import { flatMap, isEmpty, sumBy, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { useRegions } from '../../../hooks';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { useBrowseNodeMap } from '../../../hooks/useMaterialCategory';
import { DropdownSelect, LoadingButton, RcylDatePicker } from '../../../shared/Components';
import { categoryName } from '../../../shared/Constants/Constants';
import { getGstRate, setObjectToUrlParam, toServerDate } from '../../../shared/Utils/Helper';
import { createDraftWorkOrder } from '../../../views/Planning/service';
import { generateMarketPlaceWorkOrder } from '../MarketPlaceCredits/service';
import './PlanningModule.scss';
import POInputForm from './POInputForm';
import ReconfirmModal from './ReconfirmModal';

export default function PlanningWOsCreationModal(props) {
  const {
    showModal,
    handleCloseModal,
    workOrderForm,
    isMarketPlace,
    generateWoDeliveries,
    setApiCalled,
    setCallAPI,
    handleTabSelect,
    planData,
    hasItem,
    isAddSupply,
    supplyTab,
    unitId,
    advancePORecyclersData,
    isAdvancePOSP,
    isAddSupplyNA,
    isAdvancePOPWP,
    isAdvancePOSPwithnoRecycler,
  } = props;
  const recyclerOptions = advancePORecyclersData;
  const history = useHistory();
  const { itemsById } = useBrowseNodeMap();
  const [workOrderCreationLoading, setWorkOrderCreationLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const { selectedFinancialYear } = useFinancialYear();
  const [workOrderErrorMsg, setWorkOrderErrorMsg] = useState('');
  const [showConfirmPopover, setShowConfirmPopover] = useState();
  const [modifiedPayload, setModifiedPayload] = useState();
  const [useOldPayload, setUseOldPayload] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const date = new Date(selectedFinancialYear?.fromDate.split('T')[0]);
  date.setDate(date.getDate() + 1);

  const browseNodes = useSelector(({ browseNodes }) => browseNodes);
  const regions = useRegions();
  const { serviceProviders } = useSelector(({ serviceProvider }) => ({
    serviceProviders: serviceProvider.listLight,
  }));
  const categoryData = browseNodes?.data
    ?.find((data) => workOrderForm?.values?.clientData?.[0]?.browseNodeId === data?.id)
    ?.items?.filter((cat) => cat?.name !== 'Any');
  const itemList = flatMap(browseNodes?.data?.map((node) => node?.items));
  const totalDeliveriesQty = sumBy(generateWoDeliveries?.map((data) => data?.qty));
  const selectedVendorStateName =
    workOrderForm?.values?.selectedVendorData?.stateName ||
    regions?.find((item) => item.id == workOrderForm?.values?.selectedClientData?.[0]?.stateId)
      ?.name;

  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e', '='];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
  };

  const handleWorkOrderChange = (indexValue, type, value) => {
    const selectedClientData = workOrderForm?.values?.selectedClientData;
    if (type === 'recyclerCustomerId') {
      workOrderForm.setFieldValue(
        'selectedClientData',
        selectedClientData?.map((data, rowIndex) => {
          if (rowIndex === indexValue) {
            return {
              ...data,
              toDate: type === 'fromDate' ? '' : type === 'toDate' ? value : data?.toDate,
              [type]: value,
              recyclerName:
                (supplyTab === 'advancePO' || isAddSupplyNA) &&
                type === 'recyclerCustomerId' &&
                value === 0
                  ? 'NA'
                  : data?.recyclerName ||
                    serviceProviders?.find((item) => item?.id === data?.customerId)?.businessName ||
                    serviceProviders?.find((item) => item?.id === data?.recyclerCustomerId)
                      ?.businessName,
            };
          } else return data;
        })
      );
    } else {
      workOrderForm.setFieldValue(
        'selectedClientData',
        selectedClientData?.map((data, rowIndex) => {
          if (rowIndex === indexValue) {
            return {
              ...data,
              toDate: type === 'fromDate' ? '' : type === 'toDate' ? value : data?.toDate,
              [type]: value,
            };
          } else return data;
        })
      );
    }
  };

  const generateWoForMpDeliveries = async () => {
    setWorkOrderCreationLoading(true);
    const paramsClientData = workOrderForm?.values?.selectedClientData[0];
    const paramsDeliveryData = workOrderForm?.values?.selectedDeliveriesData;
    const districtIds = uniq(paramsDeliveryData.map((delivery) => delivery?.districtId));

    const params = {
      executionMode: 'SERVICE_PROVIDER',
      toCustomerId: paramsDeliveryData[0].recyclerCustomerId,
      draft: false,
      paymentTerm: Number(paramsClientData?.paymentTerm),
      poDate: paramsClientData?.poDate,
      fromDate: paramsClientData?.fromDate,
      toDate: paramsClientData?.toDate,
      status: 'IN_PROGRESS',
      remarks: null,
      workOrderItems: districtIds.map((id) => {
        const qty = sumBy(
          paramsDeliveryData
            .filter((delivery) => id === delivery?.districtId)
            .map((filteredDlvry) => filteredDlvry?.qty)
        );
        return {
          browseNodeId: itemsById[paramsDeliveryData[0].itemId]?.browseNodeId,
          itemId: paramsDeliveryData[0]?.itemId,
          stateId: paramsDeliveryData[0]?.stateId,
          districtId: id,
          pricePerUnit: Number(paramsClientData?.rate),
          qty: qty,
          estimatedDelivery: paramsClientData?.toDate,
          fulfillmentCycle: paramsClientData?.fulfillmentCycle?.value,
        };
      }),
      deliveryIds: workOrderForm?.values?.selectedDeliveriesData?.map((data) => data?.id),
    };
    const response = await generateMarketPlaceWorkOrder(params);
    if (response?.status === 201) {
      toast.success(`Work Order Generated Succesfully`);
      setApiCalled((prev) => prev + 1);
      workOrderForm.resetForm();
      handleCloseModal();
    }
  };
  useEffect(() => {
    if (isEmpty(browseNodes)) setMaterials([]);
    else {
      const materialList = [];
      const nodes = browseNodes?.data ?? [];
      nodes.forEach((items) => {
        const ITEMS = items?.items ?? [];
        ITEMS.forEach((material) => materialList.push(material));
      });
      setMaterials(materialList);
    }
  }, [browseNodes]);

  const getCustomizedPayload = (arg, data) => {
    switch (arg) {
      case 'recyclerCustomerId':
        return supplyTab !== 'advancePO' && !isAddSupplyNA
          ? data?.customerId
          : (supplyTab === 'advancePO' && data?.recyclerCustomerId === 0) ||
            (supplyTab === 'advancePO' && data?.recyclerId === 0) ||
            (isAddSupplyNA && data?.recyclerCustomerId === 0) ||
            (isAddSupplyNA && data?.recyclerId === 0)
          ? null
          : data?.recyclerCustomerId || data?.recyclerId || data?.customerId || null;
      case 'recyclerName':
        return supplyTab !== 'advancePO' && !isAddSupplyNA
          ? data?.recyclerName ||
              serviceProviders?.find((item) => item?.id === data?.customerId)?.businessName
          : serviceProviders?.find((item) => item?.id === data?.recyclerCustomerId)?.businessName ||
              serviceProviders?.find((item) => item?.id === data?.recyclerId)?.businessName ||
              data?.recyclerName ||
              'NA';
    }
  };

  const generateWorkOrder = async () => {
    setWorkOrderCreationLoading(true);
    const payload = {
      brandWoLineItemId: workOrderForm?.values?.clientData?.find((data) => data?.isRowSelected)
        ?.workOrderItemId,
      advancePO: supplyTab === 'advancePO' ? true : false,
      inventoryChecks: isAdvancePOSP || isAdvancePOPWP ? false : !isAddSupply,
      year: date.toISOString().split('T')[0],
      workOrderList: workOrderForm?.values?.selectedClientData?.map((data) => ({
        fromDate: toServerDate(data?.fromDate),
        executionMode: data?.executionMode,
        toCustomerId: data?.serviceProviderId ? data?.serviceProviderId : data?.customerId,
        recyclerCustomerId: getCustomizedPayload('recyclerCustomerId', data),
        recyclerName: getCustomizedPayload('recyclerName', data),
        toDate: toServerDate(data?.toDate),
        draft: false,
        poNumber: data?.poNumber ? data?.poNumber || '' : '',
        poDate: data?.poDate,
        paymentTerm: Number(data?.paymentTerm),
        status:
          (isAdvancePOSP && (data?.recyclerCustomerId === 0 || data?.recyclerId === 0)) ||
          isAddSupplyNA
            ? 'IN_COMPLETE'
            : 'IN_PROGRESS',
        documents: [],
        workOrderDocuments: [],
        traceabilityDocuments: planData?.traceabilityDocuments,
        hasAdvancePo: data?.hasAdvancePo,
        workOrderItems: [
          {
            workOrderFor: null,
            browseNodeId: data?.browseNodeId,
            itemId: data?.itemId,
            stateId: data?.stateId ?? null,
            districtId: data?.districtId,
            pricePerUnit: Number(data?.rate),
            lineTotal: Number(data?.allocate) * Number(data?.rate),
            qty: Number(data?.allocate),
            poLineItem: data?.poLineItemId ?? '',
            estimatedDelivery: toServerDate(data?.toDate),
            fulfillmentCycle: data?.fulfillmentCycle?.value ? data?.fulfillmentCycle?.value : null,
            allocationPlanQty: Number(data?.allocate),
            sac: data?.sac?.value || data?.sacCode,
            hsnCode: data?.sac?.label || data?.hsnCode,
            taxRate: getGstRate(materials, data?.itemId),
            hasAdvancePo: data?.hasAdvancePo,
          },
        ],
      })),
    };
    setModifiedPayload(payload);
    const resp = await createDraftWorkOrder(
      modifiedPayload && useOldPayload ? modifiedPayload : payload
    );
    if (resp?.data?.message) {
      setWorkOrderErrorMsg(`${workOrderErrorMsg},${resp?.data?.message}`);
      if (['DIFFERENT_ITEM', 'PRICE_MIS_MATCHED', 'QTY_EXCEED']?.includes(resp?.data?.errorCode)) {
        setErrorMsg(resp?.data?.message);
        setShowConfirmPopover(1);
        const finalPayload = modifiedPayload ? modifiedPayload : payload;
        if ('QTY_EXCEED' === resp?.data?.errorCode) {
          setModifiedPayload({
            ...payload,
            workOrderList: finalPayload?.workOrderList?.map((item) => ({
              ...item,
              byPassPoQtyCheck: true,
              remarks: `${resp?.data?.message},${workOrderErrorMsg}`,
            })),
          });
        }
        if (['DIFFERENT_ITEM', 'PRICE_MIS_MATCHED'].includes(resp?.data?.errorCode)) {
          setModifiedPayload({
            ...payload,
            workOrderList: finalPayload?.workOrderList?.map((item) => ({
              ...item,
              byPassPoChecks: true,
              remarks: `${resp?.data?.message},${workOrderErrorMsg}`,
            })),
          });
        }
        setUseOldPayload(true);
      } else {
        setUseOldPayload(false);
        setModifiedPayload(payload);
      }
    }
    if (resp?.status === 201 || resp?.status === 200) {
      toast.success(
        `Draft WO successfully updated for ${
          workOrderForm?.values?.selectedVendorData?.clientName
        } ${workOrderForm?.values?.selectedVendorData?.itemName}: ${
          workOrderForm?.values?.selectedVendorData?.stateName === undefined
            ? 'Geography Neutral'
            : workOrderForm?.values?.selectedVendorData?.stateName
        }`
      );
      handleTabSelect('Planned');
      handleCloseModal();
      setCallAPI(true);
      setObjectToUrlParam(
        {
          Brand: '123',
          brandwisePlanningTabType: 'Planned',
        },
        history
      );
    }

    setWorkOrderCreationLoading(false);
  };

  const checkIsFormValueEmpty = (isMarketPlace) => {
    const selectedValue = workOrderForm?.values?.selectedClientData?.map((data) => [
      isEmpty(data?.fromDate),
      isEmpty(data?.toDate),
      data?.rate,
      isEmpty(data?.fulfillmentCycle),
      !isMarketPlace && isEmpty(data?.allocate),
    ]);
    return selectedValue?.some((data) => data?.some((innerData) => innerData === true));
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmPopover((prev) => !prev);
    setErrorMsg();
    setModifiedPayload();
  };
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Body>
          <Card className="border-0">
            <Modal.Header closeButton className="border-0">
              <Modal.Title>
                <div className="modalHeader">
                  {isMarketPlace ? (
                    <Row>
                      <Col>
                        <p style={{ marginBottom: '0px' }}>
                          MARKET PLACE : {generateWoDeliveries[0]?.item}
                          {(' ', `,`)}
                          {generateWoDeliveries[0]?.state}
                          {(' ', `,`)}
                          Quantity: {totalDeliveriesQty}KG'S
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <p className="wo_creation_planning_modal_title">
                        WO creation :{' '}
                        <span className="wo_creation_planning_modal_details">
                          {workOrderForm?.values?.clientData?.[0]?.clientName} ,{' '}
                          {selectedVendorStateName === undefined
                            ? 'Geography Neutral'
                            : selectedVendorStateName}{' '}
                          ({categoryName(categoryData?.[0]?.browseNodeTitle)})
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </Modal.Title>
            </Modal.Header>
            <hr />
            <Card.Body>
              {workOrderForm?.values?.selectedClientData?.map((workOrderCreationData, index) => {
                const spName = isAdvancePOSP
                  ? serviceProviders?.find((item) => item?.id == workOrderCreationData?.recyclerId)
                      ?.businessName ||
                    serviceProviders?.find((item) => item?.id == workOrderCreationData?.customerId)
                      ?.businessName
                  : serviceProviders?.find((item) => item?.id == workOrderCreationData?.customerId)
                      ?.businessName;
                const itemId = itemList?.find((i) => i?.id === workOrderCreationData?.itemId);
                const hsnList = [
                  {
                    id: 1,
                    label: itemId?.goodsHsnCode?.id,
                    value: false,
                  },
                  {
                    id: 2,
                    label: itemId?.hsnCode?.id,
                    value: true,
                  },
                ];

                return (
                  <>
                    {isAdvancePOSPwithnoRecycler || isAddSupplyNA ? (
                      <div>
                        <p className="vendorName_planning_recycler">
                          Service Provider :{' '}
                          <span className="vendorName_planning_recycler_bold">
                            {spName
                              ? spName
                              : workOrderCreationData?.vendorName
                              ? workOrderCreationData?.vendorName
                              : workOrderCreationData?.recyclerName
                              ? workOrderCreationData?.recyclerName
                              : ' NA'}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="vendorName_planning_recycler">
                          Recycler :{' '}
                          <span className="vendorName_planning_recycler_bold">
                            {spName ||
                              workOrderCreationData?.recyclerName ||
                              workOrderCreationData?.vendorName ||
                              'NA'}{' '}
                          </span>
                        </p>
                      </div>
                    )}

                    <Row className="p-2 woDetailsRow">
                      <Form>
                        <Form.Row>
                          {((isAdvancePOSPwithnoRecycler && !workOrderCreationData?.recyclerId) ||
                            (isAddSupplyNA && !workOrderCreationData?.recyclerId)) && (
                            <Form.Group
                              as={Col}
                              xs={4}
                              className="mb-4 ml-3 planning-form-row-large">
                              <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                                {' '}
                                Recycler <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <DropdownSelect
                                value={
                                  workOrderCreationData?.recyclerCustomerId === 0
                                    ? {
                                        id: 0,
                                        recyclerCustomerId: 0,
                                        recyclerName: 'NA',
                                        recyclerId: 0,
                                      }
                                    : workOrderCreationData?.recyclerCustomerId || ''
                                }
                                onChange={(option) =>
                                  handleWorkOrderChange(
                                    index,
                                    'recyclerCustomerId',
                                    option.recyclerCustomerId
                                  )
                                }
                                options={[
                                  {
                                    id: 0,
                                    recyclerCustomerId: 0,
                                    recyclerName: 'NA',
                                    recyclerId: 0,
                                  },
                                  ...recyclerOptions[index],
                                ]}
                                placeholder="Select recycler"
                                getOptionLabel={
                                  (option) =>
                                    // isAdvancePOSPwithnoRecycler
                                    //   ?
                                    serviceProviders?.find(
                                      (item) => item?.id === option?.recyclerCustomerId
                                    )?.businessName ||
                                    serviceProviders?.find(
                                      (item) => item?.id === option?.recyclerCustomerId
                                    )?.registeredName ||
                                    'NA'
                                  // : option?.recyclerName || 'NA'
                                }
                                getOptionValue={(option) => `${option?.recyclerCustomerId}`}
                                noOptionsMessage={() => 'Recyclers not found!'}
                                valueKey={'recyclerCustomerId'}
                              />
                            </Form.Group>
                          )}
                          {supplyTab === 'advancePO' && (
                            <Form.Group
                              as={Col}
                              xs={4}
                              className="mb-4 ml-3 planning-form-row-large">
                              <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                                PO Number <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  id="poNumber"
                                  className="p-0 float-label"
                                  typitemse="text"
                                  aria-describedby="poNumberAppend"
                                  style={{
                                    backgroundColor: '#e9ecef',
                                    border: '1px solid lightgray',
                                    textAlign: 'center',
                                  }}
                                  placeholder="Enter PO Number"
                                  value={workOrderCreationData?.poNumber}
                                  disabled={true}
                                  onChange={(event) =>
                                    handleWorkOrderChange(index, 'poNumber', event?.target?.value)
                                  }
                                  onKeyDown={handleKeyDown}
                                />
                              </InputGroup>
                              {!!workOrderForm?.errors?.selectedClientData?.[index]?.poNumber && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.poNumber}
                                </div>
                              )}
                            </Form.Group>
                          )}
                          {workOrderCreationData?.showStateDropDown && (
                            <Form.Group
                              as={Col}
                              xs={4}
                              className="mb-4 ml-3 planning-form-row-large">
                              <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                                {' '}
                                State <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <DropdownSelect
                                value={workOrderCreationData?.stateId}
                                onChange={(option) =>
                                  handleWorkOrderChange(index, 'stateId', option?.id)
                                }
                                options={regions}
                                placeholder="Select State"
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => `${option?.id}`}
                                noOptionsMessage={() => 'States not found!'}
                              />
                            </Form.Group>
                          )}
                        </Form.Row>
                        <Form.Row className="planning-form-row">
                          <Form.Group
                            as={Col}
                            className="create-date-picker-bg-img-icon mb-4 planning-form-row-large">
                            <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                              WO Start Date <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <RcylDatePicker
                              inputProps={{
                                onBlur: workOrderForm.getFieldProps(
                                  `selectedClientData[${index}].fromDate`
                                ).onBlur,
                              }}
                              parentEl="#dropOffDate"
                              singleDatePicker={true}
                              controlId={'fulfillmentDateRange'}
                              placeholder="DD-MM-YYYY"
                              startDate={workOrderCreationData.fromDate}
                              onApply={({ startDate }, $event) => {
                                handleWorkOrderChange(index, 'fromDate', startDate);
                              }}
                            />
                            {workOrderForm?.touched?.selectedClientData?.[index]?.fromDate &&
                              workOrderForm?.errors?.selectedClientData?.[
                                index
                              ]?.fromDate?.trim() && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.fromDate}
                                </div>
                              )}
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="create-date-picker-bg-img-icon mb-4 planning-form-row-large">
                            <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                              WO End Date <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <RcylDatePicker
                              parentEl="#dropOffDate"
                              singleDatePicker={true}
                              controlId={'fulfillmentDateRange'}
                              placeholder="DD-MM-YYYY"
                              startDate={workOrderCreationData.toDate || ''}
                              onApply={({ startDate }, $event) => {
                                handleWorkOrderChange(index, 'toDate', startDate);
                              }}
                              inputProps={{
                                onBlur: workOrderForm.getFieldProps(
                                  `selectedClientData[${index}].toDate`
                                ).onBlur,
                              }}
                              minDate={
                                isMarketPlace
                                  ? workOrderForm?.values?.selectedClientData?.[0]?.fromDate
                                  : workOrderCreationData.fromDate
                              }
                            />
                            {workOrderForm?.touched?.selectedClientData?.[index]?.toDate &&
                              !!workOrderForm?.errors?.selectedClientData?.[index]?.toDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.toDate}
                                </div>
                              )}
                          </Form.Group>
                          <Form.Group as={Col} className="mb-4 planning-form-row-small">
                            <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                              Rate <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                id="Rate"
                                className="p-0 float-label"
                                type="number"
                                aria-describedby="RateAppend"
                                placeholder="Enter Rate"
                                style={{
                                  backgroundColor: !isMarketPlace && '#e9ecef',
                                  textAlign: 'center',
                                }}
                                value={workOrderCreationData?.rate}
                                disabled={!isMarketPlace}
                                onChange={(event) =>
                                  handleWorkOrderChange(index, 'rate', event?.target?.value)
                                }
                                onKeyDown={handleKeyDown}
                              />
                              {!!workOrderForm?.errors?.selectedClientData?.[index]?.rate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.toDate}
                                </div>
                              )}
                              <InputGroup.Append>
                                <InputGroup.Text id="RateAppend">/kg</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>

                          {!isMarketPlace && (
                            <Form.Group as={Col} className="mb-4 planning-form-row-med">
                              <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                                Allocated Target <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  id="allocatedQuantity"
                                  className="p-0 float-label"
                                  typitemse="number"
                                  aria-describedby="allocationAppend"
                                  style={{
                                    backgroundColor: !isMarketPlace && '#e9ecef',
                                    textAlign: 'center',
                                  }}
                                  placeholder="Allocated Quantity"
                                  value={workOrderCreationData?.allocate}
                                  disabled={!isMarketPlace}
                                  onChange={(event) =>
                                    handleWorkOrderChange(index, 'allocate', event?.target?.value)
                                  }
                                  onKeyDown={handleKeyDown}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="allocationAppend">KG</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              {!!workOrderForm?.errors?.selectedClientData?.[index]?.allocate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.allocate}
                                </div>
                              )}
                            </Form.Group>
                          )}
                        </Form.Row>

                        <Form.Row className="planning-form-row">
                          {/* <Form.Group as={Col} className="mb-4 planning-form-row-med">
                            <Form.Label className="mb-1 text-medium">
                              Fulfillment Cycle <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={
                                workOrderCreationData?.fulfillmentCycle ??
                                workOrderForm?.fulfillmentCycle ??
                                ''
                              }
                              onChange={(fulfillmentSelectedValue) =>
                                handleWorkOrderChange(
                                  index,
                                  'fulfillmentCycle',
                                  fulfillmentSelectedValue
                                )
                              }
                              options={FULFILLMENT_CYCLES}
                              getOptionLabel={(option) => `${option.label ?? 'Select...'}`}
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Fulfillment Cycle not found!'}
                            />
                                                     
                          </Form.Group> */}

                          <Form.Group as={Col} xs={4} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-1 text-medium wo_creation_planning_items_label">
                              Material <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={
                                // categoryData && {
                                //   ...categoryData.find(
                                //     (data) => data.id === workOrderCreationData?.itemId
                                //   ),
                                // }
                                //  ||
                                categoryData?.find((data) => data.id === hasItem) ??
                                categoryData?.find(
                                  (data) => data.id === workOrderCreationData?.itemId
                                )
                              }
                              onChange={(option) =>
                                handleWorkOrderChange(index, 'itemId', option.id)
                              }
                              isDisabled={hasItem}
                              options={categoryData}
                              getOptionLabel={(option) =>
                                `${option?.name ?? 'Select Material Type'}`
                              }
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Material data not found!'}
                            />
                            {!!workOrderForm?.errors?.selectedClientData?.[index]?.itemId && (
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {workOrderForm?.errors?.selectedClientData?.[index]?.itemId}
                              </div>
                            )}
                          </Form.Group>
                          {!isAdvancePOSP && !isAdvancePOPWP && (
                            <Form.Group as={Col} xs={4} className="mb-4 planning-form-row-med">
                              <Form.Label className="mb-1 text-medium">
                                PO Date(vendor) <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <RcylDatePicker
                                parentEl="#dropOffDate"
                                singleDatePicker={true}
                                controlId={'fulfillmentDateRange'}
                                placeholder="DD-MM-YYYY"
                                startDate={workOrderCreationData.poDate}
                                onApply={({ startDate }, $event) => {
                                  handleWorkOrderChange(index, 'poDate', startDate);
                                }}
                                // minDate={workOrderForm.fromDate}
                              />
                              {workOrderForm?.errors?.selectedClientData?.[index]?.poDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  {workOrderForm?.errors?.selectedClientData?.[index]?.poDate}
                                </div>
                              )}
                            </Form.Group>
                          )}
                          {!isAdvancePOSP && !isAdvancePOPWP && (
                            <Form.Group as={Col} xs={4} className="mb-4 planning-form-row-small ">
                              <Form.Label className="mb-1 text-medium">
                                Payment Term <span style={{ color: 'red' }}>*</span>
                              </Form.Label>
                              <Form.Control
                                {...workOrderForm.getFieldProps(
                                  `selectedClientData[${index}].paymentTerm`
                                )}
                                id="paymentTerm"
                                className="p-0 float-label"
                                type="number"
                                min={1}
                                placeholder="Payment Term"
                                value={workOrderCreationData?.paymentTerm}
                                onChange={(event) =>
                                  handleWorkOrderChange(index, 'paymentTerm', event?.target?.value)
                                }
                                onKeyDown={handleKeyDown}
                              />
                              {workOrderForm?.touched?.selectedClientData?.[index]?.paymentTerm &&
                                !!workOrderForm?.errors?.selectedClientData?.[index]
                                  ?.paymentTerm && (
                                  <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {
                                      workOrderForm?.errors?.selectedClientData?.[index]
                                        ?.paymentTerm
                                    }
                                  </div>
                                )}
                            </Form.Group>
                          )}
                          {/* <Form.Group as={Col} className="mb-4 planning-form-row-large">
                            <Form.Label className="mb-1 text-medium">
                              HSN/SAC Code <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <DropdownSelect
                              value={workOrderCreationData?.sac ? workOrderCreationData?.sac : ''}
                              onChange={(hsnSelectedValue) =>
                                handleWorkOrderChange(index, 'sac', hsnSelectedValue)
                              }
                              options={hsnList}
                              getOptionLabel={(option) => `${option.label}`}
                              getOptionValue={(option) => `${option.id}`}
                              noOptionsMessage={() => 'Options not found!'}
                            />
                           
                          </Form.Group> */}
                        </Form.Row>
                        {supplyTab !== 'advancePO' && (
                          <Form.Row className="planning-form-row">
                            <Form.Group as={Col} className="mb-4 planning-form-row-large">
                              <POInputForm
                                workOrderForm={workOrderForm}
                                index={index}
                                handleWorkOrderChange={handleWorkOrderChange}
                              />
                            </Form.Group>
                          </Form.Row>
                        )}
                      </Form>
                    </Row>
                  </>
                );
              })}
            </Card.Body>
            <Card.Footer className="bg-white cardFooter">
              <div className="buttonDivStyle">
                {isMarketPlace ? (
                  <>
                    <Button className="cancelButton" variant="light" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      onClick={generateWoForMpDeliveries}
                      disabled={workOrderCreationLoading || checkIsFormValueEmpty(isMarketPlace)}>
                      Generate WO
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="cancelButton" variant="outline" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={workOrderCreationLoading}
                      className="submitBottom"
                      loadingText={'Creating work order..'}
                      disabled={
                        workOrderCreationLoading ||
                        // checkIsFormValueEmpty() ||
                        !workOrderForm.isValid ||
                        (isAddSupplyNA &&
                          !workOrderForm?.values?.selectedClientData.some(
                            (item) => item?.recyclerCustomerId === 0 || item?.recyclerId
                          )) ||
                        (isAdvancePOSPwithnoRecycler &&
                          !workOrderForm?.values?.selectedClientData.some(
                            (item) =>
                              item?.recyclerCustomerId ||
                              item?.recyclerCustomerId === 0 ||
                              item?.recyclerId
                          )) ||
                        (isAdvancePOPWP &&
                          !workOrderForm?.values?.selectedClientData.some(
                            (item) => item?.poNumber
                          )) ||
                        (!isAdvancePOSP &&
                          !isAdvancePOPWP &&
                          !workOrderForm?.values?.selectedClientData.every(
                            (item) => item?.poDate && item?.paymentTerm
                          )) ||
                        !workOrderForm?.values?.selectedClientData?.every(
                          (item) => item?.stateId
                        ) ||
                        (supplyTab !== 'advancePO' &&
                          !workOrderForm?.values?.selectedClientData?.every((item) =>
                            item?.hasAdvancePo ? item?.poNumber?.length > 0 : true
                          ))
                      }
                      onClick={generateWorkOrder}>
                      Create Vendor WO
                    </LoadingButton>
                  </>
                )}
              </div>
            </Card.Footer>
          </Card>
        </Modal.Body>
        <ReconfirmModal
          isLoading={workOrderCreationLoading}
          modifiedPayload={modifiedPayload}
          workOrderErrorMsg={workOrderErrorMsg}
          setWorkOrderErrorMsg={setWorkOrderErrorMsg}
          setModifiedPayload={setModifiedPayload}
          handleCloseModal={handleCloseConfirmModal}
          open={showConfirmPopover}
          errorMsg={errorMsg}
          generateWorkOrder={generateWorkOrder}
        />
      </Modal>
    </div>
  );
}
